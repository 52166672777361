import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Image from "../components/image"
import firebase from "gatsby-plugin-firebase"
import ReactMarkdown from "react-markdown"

const ArticleTemplate = ({ data }) => {
  useEffect(() => {
    // effect
    firebase.analytics().logEvent("article")
    return () => {
      // cleanup
    }
  }, [])
  return (
    <Layout>
      <h1>{data.strapiArticle.title}</h1>
      <p>
        by{" "}
        <Link to={`/authors/User_${data.strapiArticle.author.id}`}>
          {data.strapiArticle.author.username}
        </Link>
      </p>
      {data.strapiArticle.image ? (
        <Img fluid={data.strapiArticle.image.childImageSharp.fluid} />
      ) : (
        <Image />
      )}
      <ReactMarkdown source={data.strapiArticle.content} />
      {/* {firebase.analytics().logEvent("article")} */}
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      title
      content
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      author {
        id
        username
      }
    }
  }
`
